import Vuex from "vuex";
import Backoffice from "./Backoffice";
import Dashboard from "./Dashboard";
import Register from "./Register";
import Reports from "./Reports";
import User from "./User";
import Log from "./Log";
import Subscriptions from "./Subscriptions";

const store = new Vuex.Store({
  modules: {
    Backoffice,
    Dashboard,
    Register,
    Reports,
    User,
    Log,
    Subscriptions
  },
  state: {},
  actions: {},
  mutations: {},
  getters: {}
});

export default store;
