import axios from "axios";
import { dispatch } from "rxjs/internal-compatibility";

export default {
  POST_REPORTS({ commit, dispatch }, _payload) {
    return new Promise((resolve, reject) => {
      const data = JSON.stringify(_payload);
      axios
        .post(DM_ENV.API_URL + "/subscribers/report", data, {
          headers: {
            "Content-type": "application/json",
            "x-access-token": localStorage.token
          }
        })
        .then(_response => {
          resolve(_response.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  SEND_REPORT_TO_EMAIL({ commit, dispatch }, _payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(DM_ENV.API_URL + "/report/to-email", _payload, {
          headers: {
            "Content-type": "application/json",
            "x-access-token": localStorage.token
          }
        })
        .then(_response => {
          resolve(_response.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};
