let roles = ["read", "edit"];


let backofficeRouter = [
  {
    path: "/backoffice",
    name: "backoffice",
    component: () =>
      import(
        /* webpackChunkName: "backoffice" */ "@/pages/Backoffice/Backoffice"
      ),
    redirect: { name: "backoffice dashboard" },
    meta: {
      requiresAuth: true,
      roles: roles,
      showMenu: true
    },
    children: [
      {
        path: "dashboard",
        name: "backoffice dashboard",
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ "@/pages/Backoffice/Dashboard/Dashboard"
          ),
        meta: {
          requiresAuth: true,
          roles: roles,
          showMenu: true,
          title: "Dashboard"
        }
      },
      {
        path: "reports",
        name: "backoffice reports",
        component: () =>
          import(
            /* webpackChunkName: "users" */ "@/pages/Backoffice/Reports/Reports"
          ),
        meta: {
          requiresAuth: true,
          roles: roles,
          showMenu: true,
          title: "Reports"
        }
      },
      {
        path: "users",
        name: "backoffice users",
        component: () =>
          import(
            /* webpackChunkName: "users" */ "@/pages/Backoffice/Users/Users"
          ),
        meta: {
          requiresAuth: true,
          roles: roles,
          showMenu: true,
          title: "Usuários"
        }
      },
      {
        path: "users/:id",
        name: "backoffice edit users",
        component: () =>
          import(
            /* webpackChunkName: "users" */ "@/pages/Backoffice/Users/EditUser"
          ),
        meta: {
          requiresAuth: true,
          roles: roles,
          showMenu: true,
          title: "Editar Usuário"
        }
      },
      {
        path: "users/create",
        name: "backoffice create users",
        component: () =>
          import(
            /* webpackChunkName: "users" */ "@/pages/Backoffice/Users/CreateUser"
          ),
        meta: {
          requiresAuth: true,
          roles: roles,
          showMenu: true,
          title: "Criar Usuário"
        }
      },
      {
        path: "tools",
        name: "backoffice tools",
        component: () =>
          import(
            /* webpackChunkName: "tools" */ "@/pages/Backoffice/Tools/Tools"
          ),
        meta: {
          requiresAuth: true,
          roles: roles,
          showMenu: true,
          title: "Ferramentas"
        }
      },
      {
        path: "logs",
        name: "backoffice logs",
        component: () =>
          import(/* webpackChunkName: "logs" */ "@/pages/Backoffice/Logs/Logs"),
        meta: {
          requiresAuth: true,
          roles: roles,
          showMenu: true,
          title: "Logs"
        }
      },
      {
        path: "subscription/id/:id/groupId/:groupId",
        name: "backoffice subscription",
        component: () =>
          import(
            /* webpackChunkName: "subscription" */ "@/pages/Backoffice/Subscriptions/Subscription/Subscription"
          ),
        meta: {
          requiresAuth: true,
          roles: roles,
          showMenu: true,
          title: "Assinatura"
        }
      }
    ]
  }
];

export default backofficeRouter;
