import axios from "axios";
import { dispatch } from "rxjs/internal-compatibility";

export default {
  GET_SUBSCRIPTIONS({ commit }, _payload) {
    return new Promise((resolve, reject) => {
      try {
        axios
          .post(DM_ENV.API_URL + "/subscribers/list", _payload, {
            headers: {
              "Content-type": "application/json",
              "x-access-token": localStorage.token
            }
          })
          .then(_response => {
            resolve(_response.data);
          })
          .catch(_error => {
            reject(_error.error);
          });
      } catch (error) {
        reject(error);
      }
    });
  },
  GET_SUBSCRIPTION_BY_ID({ commit }, _payload) {
    return new Promise((resolve, reject) => {
      try {
        axios
          .post(
            DM_ENV.API_URL + "/subscribers/get",
            { id: _payload.subscriptionId },
            {
              headers: {
                "Content-type": "application/json",
                "x-access-token": localStorage.token
              }
            }
          )
          .then(_response => {
            resolve(_response.data.subscription);
          })
          .catch(_error => {
            reject(_error.error);
          });
      } catch (error) {
        reject(error);
      }
    });
  },
  GET_SUBSCRIPTION_BY_ID_FROM_GROUP({ commit }, _payload) {
    return new Promise((resolve, reject) => {
      try {
        axios
          .post(
            DM_ENV.API_URL + "/subscribers/get-from-group",
            _payload,
            {
              headers: {
                "Content-type": "application/json",
                "x-access-token": localStorage.token
              }
            }
          )
          .then(_response => {
            resolve(_response.data.subscription);
          })
          .catch(_error => {
            reject(_error.error);
          });
      } catch (error) {
        reject(error);
      }
    });
  },
  CANCEL_SUBSCRIPTION_BY_ID({ commit }, _payload) {
    return new Promise((resolve, reject) => {
      try {
        axios
          .post(
            DM_ENV.API_URL + "/subscribers/cancel",
            _payload,
            {
              headers: {
                "Content-type": "application/json",
                "x-access-token": localStorage.token
              }
            }
          )
          .then(_response => {
            resolve(_response.data);
          })
          .catch(_error => {
            reject(_error);
          });
      } catch (error) {
        reject(error);
      }
    });
  }
};
