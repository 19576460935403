import axios from "axios";
import Config from "@/config/config";
import Vue from "vue";
import { dispatch } from "rxjs/internal-compatibility";

const CONFIG = new Config();

export default {
  LOGIN({ commit, dispatch }, _payload) {
    return new Promise((resolve, reject) => {
      try {
        axios
          .post(DM_ENV.API_URL + "/auth/login", _payload, {
            headers: {
              "Content-type": "application/json"
            }
          })
          .then(_response => {
            let data = _response.data;
            localStorage.token = data.token;
            localStorage.data = JSON.stringify(data.user);

            commit("setUserLogin", Vue.$jwt.decode(data.token));
            commit("User/setAdminUserInfo", data.user, {
              root: true
            });

            resolve(data);
          })
          .catch(_error => {
            reject(_error.error);
          });
      } catch (error) {
        reject(error);
      }
    });
  },
  FORGOT_PASSWORD({}, _payload) {
    return new Promise((resolve, reject) => {
      try {
        axios
          .post(DM_ENV.API_URL + "/api/admin/forgotPassword", _payload)
          .then(_response => {
            resolve(_response);
          })
          .catch(_error => {
            reject(_error.error);
          });
      } catch (error) {
        reject(error);
      }
    });
  },
  CREATE_USER({}, _payload) {
    /* _payload need to be as bellow
      {
          "name": "user name",
          "email": "email@email.net",
          "password": "password"
      }
    */
    return new Promise((resolve, reject) => {
      axios
        .post(DM_ENV.API_URL + "/api/admin/create", _payload, {
          headers: {
            "Content-type": "multipart/form-data",
            Authorization: localStorage.token
          }
        })
        .then(_response => {
          resolve(_response.data.data);
        })
        .catch(_error => {
          reject(_error.error);
        });
    });
  },
  VALIDATE_LOGIN({}, _payload) {
    return new Promise((resolve, reject) => {
      try {
        axios
          .post(DM_ENV.API_URL + "/auth/validate-token", null, {
            headers: {
              "x-access-token": _payload
            }
          })
          .then(_response => {
            let data = _response.data;
            if (data.auth) {
              resolve(data);
            } else {
              reject(data);
            }
          })
          .catch(_error => {
            reject(_error.error);
          });
      } catch (error) {
        reject(error);
      }
    });
  }
};
