import Vue from "vue";
import Router from "vue-router";

import ContainerTemplate from "@/pages/Template/Container";

import authRoutes from "@/config/routes/auth";
import backofficeRouter from "@/config/routes/backoffice/backoffice";
import LoginValidate from "./LoginValidate";

Vue.use(Router);

const { NODE_ENV } = process.env

const APP_PRODUCTION_PATH = process.env.APP_PRODUCTION_PATH

const ROOT_PATH = NODE_ENV === "production"? APP_PRODUCTION_PATH : "/"


const router = new Router({
  mode: "history",
  base: ROOT_PATH,
  linkExactActiveClass: "active",
  routes: [
    {
      path: "/",
      component: ContainerTemplate,
      children: [...authRoutes, ...backofficeRouter]
    },
    {
      path: "*",
      redirect: "/error",
      meta: {
        title: "404"
      }
    }
  ]
});

router.beforeEach((to, from, next) => {
  let getToken = localStorage.token ? localStorage.token : null;
  let token = Vue.$jwt.decode(getToken);
  const loginValidate = new LoginValidate(to, token);
  document.title = to.meta.title
    ? `Thomson Reuters | ${to.meta.title}`
    : "Thomson Reuters | Dashboard";
  next(loginValidate.validateToken());
});

export default router;
