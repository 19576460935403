
const { NODE_ENV } = process.env

const APP_PRODUCTION_PATH = process.env.APP_PRODUCTION_PATH


let authRoutes =  [
  {
      path: '/',
      name: 'auth',
      component: () => import(/* webpackChunkName: "backoffice" */ '@/pages/Backoffice/Backoffice'),
      redirect: { name: 'login' },
      children: [
        {
          path: 'login',
          name: 'login',
          component: () => import(/* webpackChunkName: "login" */ '@/pages/Backoffice/Auth/Login/PageLogin'),
          meta: {
            requiresAuth: false,
            userLogged: true,
            showMenu: false,
            title: 'login'
          }
        },
        {
          path: 'forgot',
          name: 'forgot',
          component: () => import(/* webpackChunkName: "forgotPassword" */ '@/pages/Backoffice/Auth/ForgotPassword/PageForgotPassword'),
          meta: {
            requiresAuth: false,
            userLogged: true,
            showMenu: false,
            title: 'forgot password'
          }
        },
        {
          path: 'error',
          name: 'error',
          component: () => import(/* webpackChunkName: "error" */ '@/pages/Backoffice/Auth/Error/Error404'),
          meta: {
            requiresAuth: false,
            userLogged: true,
            showMenu: false,
            title: 'error'
          }
        },
      ]
  },
]


export default authRoutes
