import Vue from "vue";
import "vue-use-vuex";
import store from "./store";
import router from "./config/router";
import Config from "./config/config";
import axios from "axios";
import VueAxios from "vue-axios";
import VueJWT from "vuejs-jwt";
import VueMoment from "vue-moment";
import moment from "moment-timezone";
import Vuelidate from "vuelidate";
import FunctionalCalendar from "vue-functional-calendar";
import App from "./App.vue";

import "./assets/styles/main.scss";

Vue.config.productionTip = false;

Vue.prototype.CONFIG = new Config();
Vue.use(VueAxios, axios);
Vue.use(VueJWT);
Vue.use(Vuelidate);
moment.locale("pt_br");
Vue.use(VueMoment, {
  moment
});

Vue.use(FunctionalCalendar, {
  dayNames: ["Seg", "Ter", "Qua", "Qui", "Sex", "Sab", "Dom"],
  monthNames: [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro"
  ],
  shortMonthNames: [
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez"
  ]
});

require("./config/eventBus");

moment.tz.setDefault("UTC");

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount("#app");
