import axios from "axios";
import Config from "@/config/config";
import Vue from "vue";
import { dispatch } from "rxjs/internal-compatibility";

const CONFIG = new Config();

export default {
  GET_ADMIN_USERS_LIST({ commit, dispatch }, _payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(DM_ENV.API_URL + "/api/admin/list", _payload, {
          headers: {
            "Content-type": "application/json",
            Authorization: localStorage.token
          }
        })
        .then(_response => {
          resolve(_response.data.data);
        })
        .catch(_error => {
          reject(_error.error);
        });
    });
  },
  GET_USERS_LIST({ commit, dispatch }, _payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(DM_ENV.API_URL + "/users", _payload, {
          headers: {
            "Content-type": "application/json",
            "x-access-token": localStorage.token
          }
        })
        .then(_response => {
          resolve(_response.data || []);
        })
        .catch(_error => {
          reject(_error.error);
        });
    });
  },
  GET_ADMIN_USER_BY_ID({ commit, dispatch }, _payload) {
    return new Promise((resolve, reject) => {
      let payload = {
        filter: {
          id: _payload
        }
      };
      axios
        .post(DM_ENV.API_URL + "/users/", payload, {
          headers: {
            "Content-type": "application/json",
            "x-access-token": localStorage.token
          }
        })
        .then(_response => {
          resolve(_response.data || []);
        })
        .catch(_error => {
          reject(_error.error);
        });
    });
  },
  GET_ADMIN_BY_ID({ commit, dispatch }, _payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(DM_ENV.API_URL + "/users/", _payload, {
          headers: {
            "Content-type": "application/json",
            Authorization: localStorage.token
          }
        })
        .then(_response => {
          resolve(_response.data.data.admin_info);
        })
        .catch(_error => {
          reject(_error.error);
        });
    });
  },
  GET_USER_BY_ID({ commit, dispatch }, _payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(DM_ENV.API_URL + "/users/" + _payload, {
          headers: {
            "Content-type": "application/json",
            Authorization: localStorage.token
          }
        })
        .then(_response => {
          resolve(_response.data);
        })
        .catch(_error => {
          reject(_error.error);
        });
    });
  },
  UPDATE_ADMIN_USER({ commit, dispatch }, _payload) {
    /* _payload need to be as bellow
      {
        "admin_id": 1,
        "admin_info": {
            "name" : "User name"
        }
      }
    */
    return new Promise((resolve, reject) => {
      axios
        .post(DM_ENV.API_URL + "/users/edit", _payload, {
          headers: {
            "Content-type": "application/json",
            "x-access-token": localStorage.token
          }
        })
        .then(_response => {
          resolve(_response.data);
        })
        .catch(_error => {
          reject(_error.error);
        });
    });
  },
  UPDATE_PROFILE({ commit, dispatch }, _payload) {
    /* _payload need to be as bellow
      {
        "admin_info": {
          "name" : "User name",
          "image" : ""
        }
      }
    */
    return new Promise((resolve, reject) => {
      axios
        .post(DM_ENV.API_URL + "/api/admin/updateProfile", _payload, {
          headers: {
            "Content-type": "multipart/form-data",
            Authorization: localStorage.token
          }
        })
        .then(_response => {
          commit("setAdminUserInfo", _response.data.data.admin_info);
          resolve(_response.data.data);
        })
        .catch(_error => {
          reject(_error.error);
        });
    });
  },
  DELETE_ADMIN_USER({ commit, dispatch }, _payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(DM_ENV.API_URL + "/users/delete", _payload, {
          headers: {
            "Content-type": "application/json",
            "x-access-token": localStorage.token
          }
        })
        .then(_response => {
          resolve(_response.data);
        })
        .catch(_error => {
          reject(_error.error);
        });
    });
  },
  SET_ADMIN_USER_INFO({ commit, dispatch }, _payload) {
    return new Promise((resolve, reject) => {
      let tokenDecoded = Vue.$jwt.decode(_payload);
      commit("Register/setUserLogin", tokenDecoded, { root: true });
      commit("User/setAdminUserInfo", JSON.parse(localStorage.data), {
        root: true
      });
      /*
      CODE-SMELL: this "if" is being used just because API is not working yet.
      When API works, just remove the whole if and uncomment the dispatch block
      */
      if (tokenDecoded && tokenDecoded.id) {
        return resolve(tokenDecoded);
      } else {
        reject("Token inválido");
      }

      /* dispatch("GET_ADMIN_USER_BY_ID", tokenDecoded.id)
        .then(_response => {
          resolve(_response);
        })
        .catch(_error => {
          reject(_error.error);
        }); */
    });
  },
  GET_ADMIN_TYPES({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      axios
        .get(DM_ENV.API_URL + "/api/adminTypes/enumerate", {
          headers: {
            "Content-type": "application/json",
            Authorization: localStorage.token
          }
        })
        .then(_response => {
          commit("setAdminTypes", _response.data.data.admin_type_info);
          resolve(_response.data.data.admin_type_info);
        })
        .catch(_error => {
          reject(_error.error);
        });
    });
  }
};
