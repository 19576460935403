import { dispatch } from "rxjs/internal-compatibility";

export default {
  GET_LOGS({ commit }) {
    return new Promise((resolve, reject) => {
      let data = [
        {
          id: "2CECFB83E86940678855913CDD34490D",
          customerId: "wesley.silva.mazzatech@infracommerce.com.br",
          userProfileId: "4f29d0c6-b893-4793-8005-e00b9f8c02e7",
          orderGroup: "85C8131AE9C0C34AB3C8FBABB4E81024",
          status: "ACTIVE"
        }
      ];
      return resolve(data);
    });
  }
};
