import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

const state = () => ({
  info: null,
  searchConfig: {
    searchTerm: "",
    search: {
      filter: {},
      pagination: {
        page: 1,
        paginate: 5,
      }
    },
  }
});

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
