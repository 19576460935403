import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

let state = () => ({});

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
