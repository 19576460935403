import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

const state = () => ({
  login: null,
  checkAuth() {
    return localStorage.getItem("token");
  },
  logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("data");
    this.userLoggedIn = false;
  }
});

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
