export default class LoginValidate {
  constructor(to, token) {
    this.to = to;
    this.token = token;
    this.needToAuthorize = to.meta.requiresAuth;
    this.isAnonymous = this.token !== null && this.token.id ? false : true;
  }

  getPageToGo(page) {
    return { name: page };
  }

  validateToken() {
    if (this.needToAuthorize) {
      let redirect = !this.isAnonymous
        ? false
        : this.getAnonymousRoutes()
        ? false
        : true;
      return !this.token | redirect ? this.eraseToken() : true;
    } else {
      if (!!this.token && !this.isAnonymous && this.to.name == "login") {
        return this.getPageToGo("backoffice");
      } else if (this.getAnonymousRoutes()) {
        return true;
      } else {
        return this.eraseToken();
      }
    }
  }

  getAnonymousRoutes() {
    const openRoutes = ["login", "forgot", "error"];
    let search = openRoutes.filter(isOpen => this.to.name == isOpen);
    let routeEnabled = search.length > 0 ? true : false;
    return routeEnabled;
  }

  eraseToken() {
    localStorage.removeItem("token");
    localStorage.removeItem("data");
    return this.getPageToGo("login");
  }
}
