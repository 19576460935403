import axios from "axios";

export default {
  INTEGRATE_SUBSCRIPTIONS({ commit }, _payload) {
    return new Promise((resolve, reject) => {
      try {
        axios
          .post(DM_ENV.API_URL + "/subscribers/integrate", _payload, {
            headers: {
              "Content-type": "application/json",
              "x-access-token": localStorage.token
            }
          })
          .then(_response => {
            resolve(_response.data);
          })
          .catch(_error => {
            reject(_error.error);
          });
      } catch (error) {
        reject(error);
      }
    });
  }
};
