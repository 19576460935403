class Config {
  constructor() {
    this.env = process.env.APP_ENV;
    this.isMobile =
      typeof window.orientation !== "undefined" ||
      navigator.userAgent.indexOf("IEMobile") !== -1;
  }
}

export default Config;
